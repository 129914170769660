import React from 'react';
import { Link } from 'react-router-dom';
import { Wine, Search, Upload } from 'lucide-react';

const Home = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-900 sm:text-5xl md:text-6xl">
          Discover Artisanal Wines
        </h1>
        <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          Connect with independent winemakers and explore unique wines from emerging regions.
        </p>
        
        <div className="mt-10 flex justify-center gap-6">
          <Link
            to="/directory"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700"
          >
            <Search className="mr-2 h-5 w-5" />
            Browse Directory
          </Link>
          <Link
            to="/submit-wine"
            className="inline-flex items-center px-6 py-3 border border-purple-600 text-base font-medium rounded-md text-purple-600 bg-white hover:bg-purple-50"
          >
            <Upload className="mr-2 h-5 w-5" />
            List Your Wine
          </Link>
        </div>
      <div className="mt-20">
        <a
          href="https://ship.qvevri.online/"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100"
        >
          Apply to Become a Preferred Shipper
        </a>
      </div>
      </div>
    </div>
  );
};

export default Home; 