// components/ContactForm.jsx
import React, { useState } from 'react';
import { Send } from 'lucide-react';
import { API_ROUTES, fetchWithTimeout } from '../utils/api';

const validateEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const sanitizeInput = (input) => {
  return typeof input === 'string' 
    ? input.trim().replace(/[<>]/g, '') 
    : input;
};

export const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = 'Name is required';
    if (!formData.email) errors.email = 'Email is required';
    if (!validateEmail(formData.email)) errors.email = 'Invalid email format';
    if (!formData.subject) errors.subject = 'Subject is required';
    if (!formData.message) errors.message = 'Message is required';
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setValidationErrors({});
    
    // Validate form
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      const sanitizedData = {
        ...formData,
        name: sanitizeInput(formData.name),
        email: sanitizeInput(formData.email),
        subject: sanitizeInput(formData.subject),
        message: sanitizeInput(formData.message),
        id: `msg_${Math.random().toString(36).substr(2, 9)}`,
        timestamp: new Date().toISOString(),
        status: 'new'
      };

      await fetchWithTimeout(API_ROUTES.messages, {
        method: 'POST',
        body: JSON.stringify(sanitizedData)
      });

      setShowSuccess(true);
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });

      setTimeout(() => {
        setShowSuccess(false);
      }, 5000);
      
    } catch (error) {
      console.error('Error submitting contact form:', error);
      setError(error.message || 'Failed to send message. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Basic validation for numeric fields
    if (name === 'price' || name === 'minOrder') {
      if (value < 0) return;
    }

    if (name.startsWith('contact.')) {
      const contactField = name.split('.')[1];
    setFormData(prev => ({
      ...prev,
    contact: {
            ...prev.contact,
            [contactField]: value
          }
        }));
      } else {
        setFormData(prev => ({
          ...prev,
          [name]: value
        }));
      }
    };

  return (
    <div className="max-w-md mx-auto">
      <div className="text-center mb-4">
        <h3 className="text-lg font-medium text-purple-100 mb-2">Contact Us</h3>
      </div>

      {showSuccess && (
        <div className="mb-4 p-2 bg-green-500 text-white rounded-lg text-center">
          Message sent successfully! 📬
        </div>
      )}

      {error && (
        <div className="mb-4 p-2 bg-red-500 text-white rounded-lg text-center">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-3">
        <div className="grid grid-cols-2 gap-3">
          <div>
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              required
              className="w-full px-3 py-2 bg-purple-800 text-white placeholder-purple-300 border border-purple-700 rounded-lg focus:outline-none focus:border-purple-500"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              required
              className="w-full px-3 py-2 bg-purple-800 text-white placeholder-purple-300 border border-purple-700 rounded-lg focus:outline-none focus:border-purple-500"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
        </div>

        <div>
          <input
            type="text"
            name="subject"
            placeholder="Subject"
            required
            className="w-full px-3 py-2 bg-purple-800 text-white placeholder-purple-300 border border-purple-700 rounded-lg focus:outline-none focus:border-purple-500"
            value={formData.subject}
            onChange={handleChange}
          />
        </div>

        <div>
          <textarea
            name="message"
            placeholder="Your Message"
            required
            rows="3"
            className="w-full px-3 py-2 bg-purple-800 text-white placeholder-purple-300 border border-purple-700 rounded-lg focus:outline-none focus:border-purple-500 resize-none"
            value={formData.message}
            onChange={handleChange}
          />
        </div>

        <button
          type="submit"
          className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-500 transition-colors"
        >
          <Send size={16} />
          Send Message
        </button>
      </form>
    </div>
  );
};