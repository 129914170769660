import React from 'react';

export const Terms = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
      
      <div className="space-y-6 text-gray-700">
        <section>
          <h2 className="text-xl font-semibold mb-3">Agreement to Terms</h2>
          <p>By accessing our platform, you agree to be bound by these Terms of Service.</p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3">User Responsibilities</h2>
          <ul className="list-disc pl-6 mt-2">
            <li>Provide accurate information</li>
            <li>Maintain confidentiality of account</li>
            <li>Comply with applicable laws and regulations</li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3">Wine Listings</h2>
          <p>Sellers must:</p>
          <ul className="list-disc pl-6 mt-2">
            <li>Accurately represent their wines</li>
            <li>Comply with local alcohol regulations</li>
            <li>Maintain proper licensing</li>
          </ul>
        </section>
      </div>
    </div>
  );
}; 