import React from 'react';

export const Privacy = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      
      <div className="space-y-6 text-gray-700">
        <section>
          <h2 className="text-xl font-semibold mb-3">Information We Collect</h2>
          <p>We collect information that you provide directly to us, including:</p>
          <ul className="list-disc pl-6 mt-2">
            <li>Contact information (name, email, phone number)</li>
            <li>Business information for wine producers</li>
            <li>Wine submission details</li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3">How We Use Your Information</h2>
          <p>We use the information we collect to:</p>
          <ul className="list-disc pl-6 mt-2">
            <li>Facilitate connections between buyers and sellers</li>
            <li>Improve our services</li>
            <li>Send important updates about our platform</li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3">Data Protection</h2>
          <p>We implement appropriate security measures to protect your personal information.</p>
        </section>
      </div>
    </div>
  );
}; 