// src/utils/api.js

export const API_ROUTES = {
  messages: '/api/messages',
  wines: '/api/wines',
  submissions: '/api/submissions'
};

export const fetchWithTimeout = async (url, options = {}, timeout = 5000) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  try {
    const response = await fetch(url, {
      ...options,
      signal: controller.signal,
      headers: {
        'Content-Type': 'application/json',
        'CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.content || '',
        ...options.headers,
      }
    });
    clearTimeout(id);
    
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
    }
    
    return await response.json();
  } catch (error) {
    clearTimeout(id);
    if (error.name === 'AbortError') {
      throw new Error('Request timeout');
    }
    if (!navigator.onLine) {
      throw new Error('No internet connection');
    }
    throw error;
  }
};