import React from 'react';
import { Link, NavLink } from 'react-router-dom';

export const Header = () => {
  return (
    <div className="bg-purple-900 text-white py-16 shadow-lg">
      <div className="container mx-auto px-4">
        <Link to="/" className="block">
          <h1 className="text-5xl font-bold mb-4">Artisanal Wine Directory</h1>
          <p className="text-xl mb-6">Discover rare wines from emerging wine regions</p>
        </Link>
        <nav className="mt-4">
          <NavLink 
            to="/" 
            className={({ isActive }) => 
              `mr-4 transition ${isActive ? 'text-purple-300' : 'hover:text-purple-300'}`
            }
          >
            Home
          </NavLink>
          <NavLink 
            to="/directory" 
            className={({ isActive }) => 
              `mr-4 transition ${isActive ? 'text-purple-300' : 'hover:text-purple-300'}`
            }
          >
            Directory
          </NavLink>
          <NavLink 
            to="/regions" 
            className={({ isActive }) => 
              `mr-4 transition ${isActive ? 'text-purple-300' : 'hover:text-purple-300'}`
            }
          >
            Wine Regions
          </NavLink>
          <NavLink 
            to="/about" 
            className={({ isActive }) => 
              `mr-4 transition ${isActive ? 'text-purple-300' : 'hover:text-purple-300'}`
            }
          >
            About
          </NavLink>
          <NavLink 
            to="/submit-wine" 
            className={({ isActive }) => 
              `mr-4 transition ${isActive ? 'text-purple-300' : 'hover:text-purple-300'}`
            }
          >
            Submit Wine
          </NavLink>
        </nav>
      </div>
    </div>
  );
};
