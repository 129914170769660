import React, { useState, useEffect } from 'react';
import { SearchFilters } from '../components/SearchFilters';
import { WineCard } from '../components/WineCard';
import { wineData } from '../data/wineData';

const ITEMS_PER_PAGE = 10;

export const WineDirectory = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState('all');
  const [selectedCountry, setSelectedCountry] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortOption, setSortOption] = useState('name');

  const wineTypes = ['Red', 'White', 'Orange', 'Rosé', 'Sparkling'];
  const countries = ['Georgia', 'Moldova', 'Romania'];

  const filteredWines = wineData.filter(wine => {
    const matchesSearch = wine.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         wine.winery.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesType = selectedType === 'all' || wine.type === selectedType;
    const matchesCountry = selectedCountry === 'all' || wine.country === selectedCountry;
    return matchesSearch && matchesType && matchesCountry;
  });

  useEffect(() => {
    setTotalPages(Math.ceil(filteredWines.length / ITEMS_PER_PAGE));
  }, [filteredWines]);

  const paginatedWines = filteredWines.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

  const sortedWines = [...paginatedWines].sort((a, b) => {
    if (sortOption === 'price') {
      return a.price - b.price;
    }
    return a.name.localeCompare(b.name);
  });

  return (
    <div className="container mx-auto px-4 py-8">
      <SearchFilters
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        wineTypes={wineTypes}
        countries={countries}
        
      />
      <div style={{ marginRight: '12px', marginBottom: '16px' }}>
        <select onChange={(e) => setSortOption(e.target.value)} value={sortOption}>
          <option value="name">Sort by Name</option>
          <option value="price">Sort by Price</option>
        </select>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        
        {sortedWines.map(wine => (
          <WineCard key={wine.id} wine={wine} />
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between mt-4">
        <button
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="bg-purple-600 text-white px-4 py-2 rounded disabled:opacity-50"
        >
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
          className="bg-purple-600 text-white px-4 py-2 rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>

      <div className="flex justify-center mt-20">
        <button onClick={() => window.location.href='/books'} className="flex items-center text-gray-800 hover:text-purple-600">
    
          📚  Browse Wine Books
        </button>
      </div>
    </div>
  );
};
