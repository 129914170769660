export const wineData = [
  {
    id: 1,
    name: "Kakhuri Mtsvane",
    winery: "Levanis Marani",
    country: "Georgia",
    region: "Kakheti",
    type: "Orange",
    vintage: "2019",
    grape: "Kakhuri Mtsvane",
    price: 12.50,
    minOrder: 6,
    abv: "14%",
    tastingNotes: "Wine is made according to Kakhetian traditional technology, natural maceration and fermentation",
    foodPairings: "Barbecue, steak, boiled meat, fried meat, poultry, fish, pasta, cheese",
    contact: {
      name: "Levan Gogoladze",
      email: "levanismarani@gmail.com",
      phone: "+995599499020",
      website: "levanismarani.ge"
    }
  },
  {
    id: 2,
    name: "Saperavi",
    winery: "Tanini",
    country: "Georgia",
    region: "Kaxeti",
    type: "Red",
    vintage: "2021",
    grape: "Saperavi",
    price: 9.00,
    minOrder: 300,
    abv: "15%",
    tastingNotes: "Rose with a lot of fresh pomegranate",
    foodPairings: "Meat, fish",
    contact: {
      name: "Revaz Vasadze",
      email: "taniniwine@gmail.com",
      phone: "+995599901989"
    }
  },
  {
    id: 3,
    name: "POIANA FETEASCA ALBĂ",
    winery: "Poiana Winery",
    country: "Moldova",
    region: "Codry",
    type: "White",
    vintage: "2022",
    grape: "Feteasca Albă",
    price: 5.00,
    minOrder: 580,
    abv: "13.5%",
    tastingNotes: "Green apple, green pear, light field flowers",
    foodPairings: "Light salads, fish, fresh and ripened cheeses",
    contact: {
      name: "Radu Rada",
      email: "vinariapoiana@gmail.com",
      phone: "+37378980222"
    }
  },
  {
    id: 4,
    name: "Shavkapito",
    winery: "Shavgulidze Wine Cellar",
    country: "Georgia",
    region: "Kartli",
    type: "Red",
    vintage: "2022",
    grape: "Shavkapito",
    price: 8.00,
    minOrder: 500,
    abv: "14%",
    tastingNotes: "A perfumed nose of strawberry and cherry leads to a clean herbal palate with soft, light tannins (IWSC judging tasting notes)",
    foodPairings: "Mtsvadi (traditional Georgian pork barbecue), steak, stewed meat dishes, hard cheeses, nutty mushrooms, and vegetable dishes with fresh herbs",
    contact: {
      name: "Rati Shavgulidze",
      email: "rati.shavgulidze@gmail.com",
      phone: "+995 577 797812",
      website: "facebook.com"
    }
  },
  {
    id: 5,
    name: "Negru De Tomai",
    winery: "Tomai Vinex",
    country: "Moldova",
    region: "Valul lui Traian",
    type: "Red",
    vintage: "2016",
    grape: "Cabernet 50%, Merlot 25%, Saperavi 25%",
    price: 2.70,
    minOrder: 2000,
    abv: "13.5%",
    tastingNotes: "Color: dark red with ruby tint. Aroma: complex spicy-fruity, with notes of violet, spices, red fruits (pomegranate, dogwood, black currant, prunes), complemented by the aroma of aging in oak barrels with a predominance of vanilla and chocolate shades",
    foodPairings: "Red meat dishes and appetizers. Goes great with filet mignon with mushroom sauce. Can be balanced by creamy Gouda cheese",
    contact: {
      name: "Alexandr Statov",
      email: "sales@tomaiwine.com",
      phone: "+37378992100"
    }
  },
  {
    id: 6,
    name: "Qvevris Rkatsiteli",
    winery: "Telavi Old Cellar LTD",
    country: "Georgia",
    region: "Kakheti, Telavi",
    type: "Orange",
    vintage: "2020",
    grape: "Rkatsiteli",
    price: 10.00,
    minOrder: 300,
    abv: "13.5%",
    tastingNotes: "Dry fruit aromas. Taste of peach and walnuts",
    foodPairings: "Goes well with red meat and hard cheese",
    contact: {
      name: "Mariam Utiashvili",
      email: "telavioldcellar@hotmail.com",
      phone: "+995-591-703-300"
    }
  },
  {
    id: 7,
    name: "Tsolikouri",
    winery: "Minadze's Marani Phersvi",
    country: "Georgia",
    region: "Imereti",
    type: "Orange",
    vintage: "2022",
    grape: "Tsolikouri",
    price: 8.00,
    minOrder: 200,
    abv: "12%",
    tastingNotes: "Tsolikauri is a dry, light amber wine produced under the Imeretian rule with no more 30% skin contact. This wine is characterised by its pleasant acidity, long-lasting taste and aromas of pear and citrus",
    foodPairings: "Fried chicken, cow meat, Phal, cheese",
    contact: {
      name: "Irakli Minadze",
      email: "phersvi8000@gmail.com",
      phone: "+995568558252",
      website: "phersviwine.ge"
    }
  },
  {
    id: 8,
    name: "Kisi Qvevri",
    winery: "Babunidze wines",
    country: "Georgia",
    region: "Kakheti",
    type: "Orange",
    vintage: "2022",
    grape: "Kisi",
    price: 8.00,
    minOrder: 300,
    abv: "12.5%",
    tastingNotes: "It is distinguished by the aroma of forest fruits and spices; tannin rich body harmoniously complements its lingering finish",
    foodPairings: "Fried chicken, beef tongue or meaty salads",
    contact: {
      name: "Giorgi Babunidze",
      email: "gbabunidze@gmail.com",
      phone: "995593338433",
      website: "babunidze.com"
    }
  },
  {
    id: 9,
    name: "Saperavi Qvevri",
    winery: "Babunidze wines",
    country: "Georgia",
    region: "Kakheti",
    type: "Red",
    vintage: "2022",
    grape: "Saperavi",
    price: 10.00,
    minOrder: 300,
    abv: "13%",
    tastingNotes: "This wine of a happy ruby tone and an intense berry fruit character offers up plenty of bright and rich flavors",
    foodPairings: "Wine stands well on its own and is food friendly, it is good for an everyday meal and is ideal for special occasions",
    contact: {
      name: "Giorgi Babunidze",
      email: "gbabunidze@gmail.com",
      phone: "995593338433",
      website: "babunidze.com"
    }
  }
];
