// components/Footer.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { ContactForm } from './ContactForm';

export const Footer = () => {
  return (
    <footer className="bg-purple-900 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-start">
          {/* Company Info */}
          <div className="text-center md:text-left">
            <Link to="/" className="block">
              <h3 className="text-xl font-bold mb-4">Wine Artisans Directory</h3>
            </Link>
            <p className="mb-4">Connecting wine lovers with artisanal winemakers</p>
            <div className="flex flex-col space-y-2">
              <p>© 2024 Wine Artisans Directory</p>
              <div className="flex items-center justify-center md:justify-start space-x-4">
                <Link 
                  to="/privacy" 
                  className="text-purple-200 hover:text-white transition-colors"
                >
                  Privacy Policy
                </Link>
                <Link 
                  to="/terms" 
                  className="text-purple-200 hover:text-white transition-colors"
                >
                  Terms of Service
                </Link>
                <Link 
                  to="/directory" 
                  className="text-purple-200 hover:text-white transition-colors"
                >
                  Wine Directory
                </Link>
              </div>
            </div>
          </div>

          {/* Contact Form */}
          <div className="border-t md:border-t-0 md:border-l border-purple-700 pt-8 md:pt-0 md:pl-8">
            <ContactForm />
          </div>
        </div>
      </div>
    </footer>
  );
};