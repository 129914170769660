import React from 'react';
import { Search } from 'lucide-react';

export const SearchFilters = ({ 
  searchTerm, 
  setSearchTerm, 
  selectedType, 
  setSelectedType,
  selectedCountry, 
  setSelectedCountry,
  wineTypes,
  countries 
}) => {
  return (
    <div className="flex flex-col md:flex-row gap-4 mb-8">
      <div className="flex-1">
        <div className="relative">
          <Search className="absolute left-3 top-3 text-gray-400" size={20} />
          <input
            type="text"
            placeholder="Search wines or wineries..."
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      <select
        className="p-2 border border-gray-300 rounded-lg"
        value={selectedType}
        onChange={(e) => setSelectedType(e.target.value)}
      >
        <option value="all">All Types</option>
        {wineTypes.map(type => (
          <option key={type} value={type}>{type}</option>
        ))}
      </select>
      <select
        className="p-2 border border-gray-300 rounded-lg"
        value={selectedCountry}
        onChange={(e) => setSelectedCountry(e.target.value)}
      >
        <option value="all">All Countries</option>
        {countries.map(country => (
          <option key={country} value={country}>{country}</option>
        ))}
      </select>
    </div>
  );
};
