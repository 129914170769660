import React from 'react';
import { GrapeIcon, Wine, Globe, Users, ShieldCheck } from 'lucide-react';


export const AboutPage = () => {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Connecting Wine Lovers with Artisanal Producers
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Discover unique wines from emerging wine regions, support small-scale producers, 
            and be part of a growing community of wine enthusiasts.
          </p>
        </div>

        {/* Value Propositions */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          <div className="text-center p-6 rounded-lg bg-purple-50">
            <Globe className="w-12 h-12 text-purple-600 mx-auto mb-4" />
            <h3 className="text-xl font-semibold mb-2">Global Access</h3>
            <p className="text-gray-600">
              Connect directly with artisanal winemakers from emerging wine regions
            </p>
          </div>

          <div className="text-center p-6 rounded-lg bg-purple-50">
            <Users className="w-12 h-12 text-purple-600 mx-auto mb-4" />
            <h3 className="text-xl font-semibold mb-2">Quality Assurance</h3>
            <p className="text-gray-600">
              Each wine is verified and reviewed by our expert team
            </p>
          </div>

          <div className="text-center p-6 rounded-lg bg-purple-50">
            <ShieldCheck className="w-12 h-12 text-purple-600 mx-auto mb-4" />
            <h3 className="text-xl font-semibold mb-2">Secure Trading</h3>
            <p className="text-gray-600">
              Safe and transparent transactions between buyers and sellers
            </p>
          </div>
        </div>

        {/* Mission Statement */}
        <div className="bg-purple-900 text-white rounded-lg p-8 mb-16">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-4">Our Mission</h2>
            <p className="text-lg">
              To promote sustainable wine production, preserve traditional winemaking methods,
              and create fair market opportunities for small-scale producers while bringing
              unique wines to enthusiasts worldwide.
            </p>
          </div>
        </div>

        {/* Statistics */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mb-16">
          <div className="text-center">
            <div className="text-4xl font-bold text-purple-600 mb-2">150+</div>
            <div className="text-gray-600">Artisanal Producers</div>
          </div>
          <div className="text-center">
            <div className="text-4xl font-bold text-purple-600 mb-2">12</div>
            <div className="text-gray-600">Wine Regions</div>
          </div>
          <div className="text-center">
            <div className="text-4xl font-bold text-purple-600 mb-2">5000+</div>
            <div className="text-gray-600">Wine Enthusiasts</div>
          </div>
          <div className="text-center">
            <div className="text-4xl font-bold text-purple-600 mb-2">98%</div>
            <div className="text-gray-600">Satisfaction Rate</div>
          </div>
        </div>

        {/* Call to Action */}
        <div className="text-center">
          <h2 className="text-3xl font-bold mb-4">Join Our Community</h2>
          <p className="text-gray-600 mb-8">
            Whether you're a wine producer or enthusiast, become part of our growing community.
          </p>
          <div className="flex justify-center gap-4">
            <button 
              className="bg-purple-600 text-white px-6 py-3 rounded-lg hover:bg-purple-700 transition-colors"
              onClick={() => window.location.href='/submit-wine'}
            >
              List Your Wines
            </button>
            <button 
              className="border border-purple-600 text-purple-600 px-6 py-3 rounded-lg hover:bg-purple-50 transition-colors"
              onClick={() => window.location.href='/directory'}
            >
              Browse Directory
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};