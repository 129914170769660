// App.jsx
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ErrorBoundary from './components/common/ErrorBoundary';
import { LoadingSpinner } from './components/common/LoadingSpinner';
import { Header } from './components/Header';
import { Footer } from './components/Footer';

// Import pages directly since they're in the pages directory
import Home from './pages/Home';
import { WineDirectory } from './pages/WineDirectory';
import { AboutPage } from './pages/About';
import { Privacy } from './pages/Privacy';
import { Terms } from './pages/Terms';
import { WineSubmissionForm } from './pages/WineSubmissionForm';
import { NotFound } from './components/NotFound';
import { BookPlus } from 'lucide-react';


const RegionsPage = React.lazy(() => import('./pages/RegionsPage'));

export default function App() {
  return (
    <Router>
      <ErrorBoundary>
        <div className="min-h-screen bg-gray-50 flex flex-col">
          <Header />
          <main className="flex-grow">
            <div className="container mx-auto px-4 py-8">
              <Suspense fallback={<LoadingSpinner />}>
                <Routes>
                  {/* Main routes */}
                  <Route path="/" element={<Home />} />
                  <Route path="/directory" element={<WineDirectory />} />
                  <Route 
                    path="/regions" 
                    element={
                      <Suspense fallback={<LoadingSpinner />}>
                        <RegionsPage />
                      </Suspense>
                    } 
                  />
                  <Route path="/about" element={<AboutPage />} />
                  
                  {/* Form routes */}
                  <Route path="/submit-wine" element={<WineSubmissionForm />} />
                  
                  {/* Legal routes */}
                  <Route path="/privacy" element={<Privacy />} />
                  <Route path="/terms" element={<Terms />} />
                  
                  {/* 404 catch-all */}
                  <Route path="*" element={<NotFound />} />
               
                </Routes>
              </Suspense>
            </div>
          </main>
          <Footer />
        </div>
      </ErrorBoundary>
    </Router>
  );
}